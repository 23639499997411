.contact-list {
    list-style-type: none; /* Remove default bullet points */
    padding: 0; /* Remove default padding */
}

.contact-item {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #ffffff; /* White background for contact items */
    border-radius: 5px; /* Slightly rounded corners */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    justify-content: space-between; /* Space between details and buttons */
    align-items: center; /* Center items vertically */
}

.contact-details {
    flex-grow: 1; /* Allow details to take available space */
}

.contact-actions {
    display: flex; /* Flexbox for button alignment */
    gap: 10px; /* Space between buttons */
}

.edit-button, .delete-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer; /* Pointer cursor for buttons */
}

.edit-button {
    background-color: #007bff; /* Bootstrap primary color */
}

.edit-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.delete-button {
    background-color: #dc3545; /* Bootstrap danger color */
}

.delete-button:hover {
    background-color: #c82333; /* Darker shade on hover */
}

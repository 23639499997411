.ActionRouteToConsultancy {
    background: #fbbc05; /* Google yellow */
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    color: #333; /* Dark text color */
}

.ActionRouteToConsultancy .action-route-to-consultancy-content {
    flex: 1; /* Take remaining space */
    margin: 0;
    padding: 5px;
}

.action-route-consultancy {
    padding: 10px 20px; /* Adjusted padding */
    background-color: #ffffff; /* White background */
    border-radius: 5px;
    border: 2px solid #ffffff; /* White border */
    color: #e7a700; /* Google yellow text color */
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    gap: 5px;
    font-size: 1.2rem;
}

.action-route-consultancy:hover {
    background-color: #f0f0f0; /* Light grey on hover */
}

.action-route-consultancy-icon {
    font-size: 1.3rem;
}
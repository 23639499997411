/* Operator.css */

/* Basic layout */
.finance-dashboard {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.finance-view-container {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Home Screen Styling */
.home-screen {
    text-align: center;
    margin-top: 20px;
}

.welcome-message {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.action-prompt {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
}

/* Section Styling */
.finance-section {
    margin-top: 20px;
}

.finance-section-title {
    font-size: 1.5rem;
    color: #555;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: left;
}

/* List Styling */
ul {
    list-style: none;
    padding: 0;
}

.dashboard-finance-align-pictogram-text-column {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.dashboard-finance-align-pictogram-text-column:hover {
    background-color: #e3f2fd;
}

/* Icon and Text Styling */
.dashboard-finance-align-pictogram-text-column svg {
    color: #007bff;
    font-size: 2rem;
    margin-right: 10px;
}

.dashboard-finance-picto-text-fontsize {
    font-size: 1.2rem;
    color: #333;
    font-weight: 500;
}

/*!* Custom Icon Colors *!*/
/*.dashboard-finance-align-pictogram-text-column:hover svg {*/
/*    color: #0056b3;*/
/*}*/


/*.dashboard-finance-align-pictogram-row {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-around;*/
/*}*/
/*.dashboard-finance-align-pictogram-text-column {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/

/*}*/


.dashboard-finance-picto-text-fontsize {
    font-size: 1.2rem;
    color: black;
}

.finance-section-container-align-row {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

@media (max-width: 768px) {

}
.WerkBon {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.WerkBon-title {
    font-size: 24px;
    font-weight: bold;
    color: #101010;
    margin-bottom: 10px;
}

.WerkBon-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.WerkBon-invoice {
    width: 300px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
}

.WerkBon-invoice h3 {
    font-size: 18px;
    margin-bottom: 8px;
}

.WerkBon-invoice p {
    margin: 5px 0;
}

.WerkBon-tasks {
    list-style: none;
    padding: 0;
}

.WerkBon-tasks li {
    padding: 4px 0;
    font-size: 14px;
}


.WerkBon-taskTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.WerkBon-taskTable th, .WerkBon-taskTable td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.WerkBon-taskTable th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.WerkBon-taskTable td {
    background-color: #fafafa;
}



.missing-information {
    color: #d9534f;           /* Red color to highlight missing information */
    font-size: 1.5em;          /* Slightly larger font size */
    font-weight: bold;         /* Bold text for emphasis */
    text-align: center;        /* Center the text */
    background-color: #f8d7da; /* Light red background for contrast */
    padding: 10px 15px;        /* Padding for spacing around text */
    border-radius: 5px;        /* Rounded corners for a softer look */
    border: 1px solid #d9534f; /* Border matching text color */
    margin: 15px auto;         /* Centering with some vertical spacing */
    width: fit-content;        /* Fits width to content */
}

.HomepageRouteToConsultancy {
    background: #fbbc05; /* Google blue */
    display: flex;
    flex-direction: row; /* Align items horizontally */
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    color: #333333; /* Text color */
}

.HomepageRouteToConsultancy .hp-route-content-consultancy {
    flex: 1; /* Take remaining space */
    text-align: center;
    padding: 0 20px; /* Padding around content */
    margin: 0;
}

.hp-route-consultancy {
    padding: 10px 5px; /* Adjusted padding */
    background-color: #ffffff; /* White background */
    border-radius: 5px;
    border: 2px solid #ffffff; /* White border */
    color: #e7a700; /* Google yellow text color */
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    gap: 5px;
    font-size: 1.2rem;
}

.hp-route-consultancy:hover {
    background-color: #f0f0f0; /* Light grey on hover */
}

.hp-route-consultancy-icon {
    font-size: 1.5rem;
}

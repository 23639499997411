/* DashboardContactPersoonAanmaken.css */

.contactpersoon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%; /* Full width for the container */
    padding: 20px; /* Add padding for better layout */
}

h2 {
    margin-bottom: 20px; /* Space below the heading */
    color: #333; /* Dark color for better readability */
}

.form-group {
    width: 100%; /* Make each form group full width */
    margin-bottom: 15px; /* Space between form fields */
}

label {
    display: block;
    margin: 10px 0;
    font-weight: bold; /* Bold labels for clarity */
}

input {
    padding: 10px; /* Add padding for better touch targets */
    margin-top: 5px;
    width: 100%; /* Full width for input fields */
    box-sizing: border-box; /* Include padding and border in width */
    border: 2px solid #ccc; /* Border to match the box style */
    border-radius: 5px; /* Rounded corners */
    transition: border-color 0.2s; /* Smooth border transition */
}

input:focus {
    border-color: #3d4144; /* Change border color on focus */
    outline: none; /* Remove default outline */
}

button.add-klant-button, button.submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3d4144; /* Primary button color */
    color: white;
    border: none;
    border-radius: 5px; /* Consistent with other buttons */
    cursor: pointer;
    transition: background-color 0.2s; /* Smooth transition */
}

button.add-klant-button:hover,
button.submit-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Additional styling for the dynamic input fields */
input[type="text"] {
    margin-bottom: 10px; /* Space between dynamically added input fields */
}

/* DashboardWijtmanVoertuigAanmaken.css */
.DashboardWijtmanVoertuigAanmaken {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.vehicle-type-boxes {
    display: flex;
    gap: 10px;
    flex-wrap: wrap; /* To handle multiple vehicle types */
}

.box {
    padding: 10px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
    text-align: center;
}


.vehicle-type-boxes .selected {
    border-color: #3d4144;
    background-color: #3d4144;
    color: white;
}

.db-voertuig-aanmaken-verstuur {
    padding: 10px 20px;
    background-color: #3d4144;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.db-voertuig-aanmaken-verstuur:hover {
    background-color: #0056b3;
}

.why-choose-us {
    padding: 30px 20px;
    text-align: center;
    margin-top: 2rem;
    background-color: #f9f9f9;

}

.why-choose-us h2 {
    font-size: 2.3rem;
    color: #333;
    margin-bottom: 30px;
}

.reasons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;


}

.reason {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: center;
}

.reason h3 {
    font-size: 1.5rem;
    color: #1A73E8;
    margin-bottom: 10px;
}

.reason p {
    font-size: 1rem;
    color: black;
}


@media screen and (max-width: 768px) {
    .reasons {
        flex-direction: column;
    }
}
/* General Styles */
.dashboard {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
}

.dashboard-title {
    font-size: 24px;
    color: #333;
    text-align: center;
}

.form-group label {
    font-size: 1.2rem;
    color: #555;
    margin-right: 10px;
}

.form-group input[type="text"],
.form-group select {
    padding: 1.2rem;
    font-size: 1.2rem;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.db-planning-maken-taak-toevoegen {
    background-color: #122972; /* Green for add */

    display: inline-block;
    padding: 10px 15px;
    font-size: 1.2rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;

}
/* "Adres toevoegen" button - Primary action style */
.db-planning-maken-adres-toevoegen {
    background-color: #122972; /* Green for add */

    display: inline-block;
    padding: 10px 15px;
    font-size: 1.2rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;

}

.db-planning-maken-adres-toevoegen:hover {
    background-color: #0c1b4b; /* Darker green on hover */
}

/* "Afronden" button - Secondary action style */
.db-planning-maken-afronden-knop {
    background-color: #2196F3; /* Blue for finishing */

    display: inline-block;
    padding: 10px 15px;
    font-size: 1.2rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;

}

.db-planning-maken-afronden-knop:hover:not(:disabled) {
    background-color: #1E88E5; /* Darker blue on hover */
}

/* Disabled state for "Afronden" */
.db-planning-maken-afronden-knop:disabled {
    background-color: #B0BEC5; /* Light gray for disabled */
    cursor: not-allowed;
    opacity: 0.7;
}

/* "Go to Overzicht" button - Navigation style */
.db-planning-maken-overzicht {
    background-color: #FF9800; /* Orange for navigation */

    display: inline-block;
    padding: 10px 15px;
    font-size: 1.2rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
    margin-left: 1rem;

}

.db-planning-maken-overzicht:hover {
    background-color: #FB8C00; /* Darker orange on hover */
}

.customer-section {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.customers-section h3, h4 {
    margin-bottom: 10px;
    color: #333;
}


/* Aligning aankomsttijd and vertrektijd on the same row */
.time-group {
    display: flex;
    justify-content: space-between;
}

.time-group .form-group {
    flex: 1;
    margin-right: 10px;
}

.time-group .form-group:last-child {
    margin-right: 0;
}

.task-module .form-group {
    margin-bottom: 10px;
}


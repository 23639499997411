.Packages {
    font-family: Roboto, sans-serif;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;

}

.package-heading {
    color: #333;
    font-size: 2.3rem;
    margin-bottom: 1.5rem;

}

.package-row-one, .package-row-two {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
}

.package-one, .package-two, .package-three, .package-custom {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    width: 45%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.package-one:hover, .package-two:hover, .package-three:hover, .package-custom:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.package-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.package-subtitle {
    font-size: 1.2em;
    color: #4285F4;
    margin-bottom: 15px;
}

.package-paragraph {
    font-size: 1em;
    color: black;
    margin-bottom: 20px;
}

.li-package-features- {
    list-style-type: none;
    padding: 0;
}

.ul-package-feature {
    font-size: 0.9em;
    color: black;
    font-weight: bolder;
    background-color: #f1f1f1;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
}

.ul-package-feature:last-child {
    margin-bottom: 0;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .package-row-one, .package-row-two {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0rem;

    }

    .package-one, .package-two, .package-three, .package-custom {
        width: 100%;
        margin-bottom: 3rem;

    }
}


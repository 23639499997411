/* General layout and typography */
.dashboard-phonebook {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 20px;
    background-color: #f4f6f9;
    color: #333;
}

.dashboard-phonebook-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
    font-weight: bold;
}

/* Search bar with modern look */
.search-bar {
    padding: 12px;
    margin-bottom: 25px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    font-size: 1.2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.search-bar:focus {
    border-color: #007bff;
    outline: none;
}

/* List styling */
.klanten-lijst, .contact-personen-lijst {
    list-style-type: none;
    padding: 0;
}

/* Klant item with modern card look */
.klant-item {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
}

.klant-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
    background-color: #f8f9fa;
}

/* Contact person item with subtle separation */
.contactpersoon-item {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    font-size: 1.2rem;
}

/* Modern button design */
.view-contact-button {
    margin-top: 15px;
    padding: 8px 15px;
    background-color: #002083;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
    display: flex;
    align-items: center;
    vertical-align: center;
    justify-content: center;
    font-size: 1.2rem;
}

.view-contact-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

/* Modal overlay for smoother look */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Darker for more contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content with rounded corners and shadow */
.modal-content {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    transition: all 0.3s ease;
}

/* Close button with new color and hover effect */
.close-modal {
    background-color: #5d0200; /* Brighter red */
    color: white;
    border: none;
    padding: 12px 12px;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.close-modal:hover {
    background-color: #c0392b; /* Darker red on hover */
}


/* Responsive Modal - adjusts for smaller screens */
@media (max-width: 768px) {
    .modal-content {
        padding: 20px;
        max-width: 95%;
    }
}

/* Responsive Modal - adjusts for smaller screens */
@media (min-width: 768px) {
    .dashboard-phonebook {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search-bar{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
    }

}

/* Container for splitting into two equal-width sections */
.work-categories-container {
    width: 100%; /* Ensure the container takes full width */
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
}

/* First section: Overview message */
.overview-section {
    flex: 1; /* Take up 50% of the width */
    padding: 20px;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 1rem;
}
.overview-message-title {
    margin-bottom: .7rem;
}
/* Second section: Category links and buttons */
.category-buttons-container {
    flex: 1; /* Take up 50% of the width */
    padding: 20px;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

/* Styling for each category link item */
.category-link-item {
    display: block;
    text-align: center;
    padding: 15px;
    font-size: 1.2em;
    text-decoration: none;
    color: #333;
    background-color: #f0f0f0;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    align-items: center;
}

.category-link-item:hover {
    background-color: #e0e0e0;
}

/* Container for the buttons */
.category-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

/* Styling for each button */
.category-button {
    display: block;
    padding: 10px 15px;
    font-size: 1em;
    text-decoration: none;
    color: #fff;
    background-color: black;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    text-align: center;
    align-items: center;
}

.category-button:hover {
    background-color: #0056b3;
}


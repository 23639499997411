.SendWhatsAppMessage {
    display: block;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.send-wapp-message-input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    width: 300px;

}

.wapp-send-button {
    display: flex;
    align-items: center;
    background-color: #3d4144;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.wapp-send-button:hover {
    background-color: #128C7E;
}

.user-management {
    padding: 20px;
}

.user-list {
    float: left;
    width: 40%;
}

.user-details {
    float: right;
    width: 55%;
}
/* General Section Styling */
.tasks-section {
    padding: 20px;
    background-color: #f4f5f7;
    font-family: Arial, sans-serif;
}

.tasks-section h3.tasklist-section-title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 15px;
    text-align: left;
}

/* Loading and Error Text */
.loading-text {
    font-size: 1rem;
    color: #555;
}

.error-text {
    font-size: 1rem;
    color: #d9534f;
}

/* Dropdown Styling */
.tasklist-customer-dropdown {
    margin-bottom: 20px;
}

.tasklist-dropdown-label {
    font-size: 1rem;
    color: #333;
    margin-right: 10px;
}

.dropdown-select {
    padding: 8px 10px;
    font-size: 1rem;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

/* Table Styling */
.tasklist-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.tasklist-thead {
    background-color: #e0e7eb;
}

.tasklist-th,
.tasklist-table-cell {
    padding: .4rem;
    text-align: center;
    font-size: 1rem;
    border: 1px solid #ddd;
    color: #555;
}

.tasklist-tr:nth-child(even) {
    background-color: #f9fafb;
}

/* Empty Task Message */
.no-tasks-message {
    text-align: center;
    font-size: 1rem;
    color: #777;
    padding: 15px 0;
}

/* Action Buttons Styling */
.tasklist-table-cell button {
    padding: 8px 12px;
    font-size: 0.9rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.toggle-button.enable {
    background-color: #4caf50; /* Green for Enable */
}

.toggle-button.disable {
    background-color: #ff9800; /* Orange for Disable */
}

.toggle-button.enable:hover,
.toggle-button.disable:hover,
.delete-button:hover {
    opacity: 0.9;
}

.delete-button {
    background-color: #e74c3c; /* Red for Delete */
}

/* Create Tasks Button */
.create-tasks-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: darkblue;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.create-tasks-button:hover {
    background-color: #003366;
}



.tasklist-align-buttons-row {
    display: flex;
    flex-direction: row;
    gap: .3rem;
}

/* DashboardDeleteKlant.css */

.dashboard-delete-klant {
    background-color: #ffffff; /* White background */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow */
    max-width: 400px;
    margin: 40px auto; /* Center the component */
    font-family: 'Arial', sans-serif; /* Professional font */
}

.dashboard-delete-title {
    text-align: center;
    color: #2c3e50; /* Dark color for the title */
    font-size: 28px; /* Larger title */
    margin-bottom: 30px; /* Space below title */
}

.dashboard-delete-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between elements */
}

.dashboard-delete-label {
    font-size: 16px; /* Larger font size */
    color: #34495e; /* Dark gray color for labels */
}

.dashboard-delete-select {
    padding: 12px; /* Padding for the select input */
    border: 1px solid #bdc3c7; /* Light gray border */
    border-radius: 5px; /* Soft corners */
    font-size: 16px; /* Consistent font size */
    transition: border-color 0.3s; /* Smooth transition */
}

.dashboard-delete-select:focus {
    border-color: #3498db; /* Blue border on focus */
    outline: none; /* Remove default outline */
}

.dashboard-delete-button {
    padding: 12px;
    background-color: #e74c3c; /* Red color for delete button */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s; /* Smooth transition */
}

.dashboard-delete-button:hover {
    background-color: #c0392b; /* Darker red on hover */
}

.voorwerp-melden-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
}

.selectable-section {
    margin-bottom: 20px;
}

.selectable-boxes {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.selectable-box {
    flex: 1;
    padding: 15px;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background-color: #f0f0f0;
    transition: background-color 0.3s, border-color 0.3s;
}

.selectable-box.selected {
    background-color: #007bff;
    color: #fff;
    border-color: #0056b3;
}

.selectable-box:hover {
    background-color: #e0e0e0;
}

.voorwerp-melden-form label {
    display: block;
    margin-bottom: 15px;
    color: black;
    font-weight: normal;
}

.voorwerp-melden-form input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1.2rem;
}



.voorwerp-melden-send-report-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 1.2rem;
}

.voorwerp-melden-send-report-button:hover {
    background-color: #0056b3;

}
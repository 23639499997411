/* Container styling */
.dashboard-assign-distance-time {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Title styling */
.title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

/* Back button styling */
.back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-bottom: 15px;
}

.back-icon {
    font-size: 1.2rem;
    color: #333;
}

/* Table styling */
.klant-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.table-header {
    background-color: #f3f3f3;
    color: #555;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.table-row:nth-child(even) {
    background-color: #fafafa;
}

.table-cell {
    padding: 10px;
    border: 1px solid #ddd;
}

/* Input field styling */
.input-field {
    width: 90%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}

/* Action button styling */
.action-button {
    padding: 6px 12px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    gap: 5px;
}

.edit-button {
    background-color: #007bff;
    color: white;
}

.save-button {
    background-color: #28a745;
    color: white;
    margin-bottom: 5px;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
}

.action-button:hover {
    opacity: 0.9;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Donkere overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Plaatst modal bovenaan */
}

/* Modal Content */
.modal-content {
    background-color: #ffffff;
    padding: 30px;
    width: 90%;
    max-width: 500px; /* Max-breedte voor desktop */
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    position: relative; /* Om de sluitknop te positioneren */
    animation: slideIn 0.3s ease-out; /* Animatie bij openen */
}

/* Sluitknop voor de modal */
.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #34495e;
}

/* Animatie voor modal */
@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}


.dashboard-planning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2; /* Lighter background for contrast with text and items */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 25px;
    color: #2a2a2a; /* Darker text color for stronger contrast */
}

.planning-list {
    list-style: none;
    padding: 0px;

}

.planning-item {
    border: 1px solid #b0b0b0; /* Darker border for better visibility */
    border-radius: 8px;
    margin-bottom: 18px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    background-color: #ffffff; /* Bright white background for clear separation */
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.no-planning-message {
    font-size: 1.2rem;
    color: #020d56;
    text-align: center;
    margin-top: 20px;
}

.planning-item:hover {
    border-color: #2c82c9; /* Sharper blue hover color */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.planning-item-header {
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: .3rem;
    display: flex;
    justify-content: space-between;
    color: #1f1f1f; /* Very dark color for readability */
    border-bottom: 1px solid #00388d;
}

.planning-details {
    background-color: rgba(188, 211, 241, 0.47);
    color: #0a0808; /* Medium-dark gray for readable yet non-dominating text */
    display: flex;
    flex-direction: column;
    padding: .5rem;
    border-radius: 5px;

}

.planning-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.2rem;
    color: #000000;
    padding-top: .5rem;
}

.customer-list {
    margin-top: .5rem;

}

.customer-list-title {
    font-weight: bold;
    color: #2a2a2a;
}

.customer-list-items {
    list-style: none;
    padding: 0;
}

.customer-list-item {
    margin-bottom: 12px;
    padding: 12px;
    border: 1px solid #b0b0b0;
    border-radius: 6px;
    background-color: #f9f9f9; /* Light background for contrast against text */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.customer-list-item:hover {
    background-color: #e1e1e1; /* Slightly darker hover effect */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.customer-name {
    font-weight: 600;
    font-size: 1.15rem;
    color: #1f1f1f; /* Darker text for strong contrast */
}
.align-customer-time-row {
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgrey;
}
.customer-time,
.customer-created {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    color: #0c0b0b; /* Medium-dark gray for subtle details */
    margin-top: 4px;

}

.graf-task-title {
    color: #2a2a2a;
}

.graf-task-list {
    list-style: none;
    padding: 0;
}

.graf-task-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    font-size: 1.2rem;
    color: #000000; /* Consistent medium-dark gray for secondary text */
}

.graf-task-item-title {
    color: #000000;
    display: flex;
    flex-direction: column;
}
.graf-task-item-grafnr {
    color: #000000;
}

.no-graf-task,
.no-customer {
    color: #707070; /* Darker gray for no data messages for better visibility */
    font-style: italic;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 15px;
}


@media (max-width: 768px) {



}


.db-contactpersonen-view-container {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.db-contactpersonen-submenu-action-buttons {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin-top: 1rem;
}

.db-contactpersonen-submenu-action-button {
    background-color: #1A73E8;
    color: white;
    padding: 10px 12px;
    border: none;

}
/* General Layout */
.dashboard-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    margin-top: 3rem;
    transition: transform 0.3s ease; /* Add transition for smooth slide */

}
/* Class to slide the dashboard up */
.dashboard-slide-up {
    transform: translateY(-4rem); /* Adjust based on your header height */
}

/* Sidebar styles */
.dashboard-sidebar {
    position: absolute; /* Use absolute positioning */
    width: 250px;
    height: 100%; /* Ensure it covers the full height */
    background-color: #002083;
    color: #ecf0f1;
    padding: 20px;
    box-shadow: 2px 0 5px rgb(0, 28, 122);
    border-radius: 8px;
    transition: transform 0.3s ease; /* Transition effect */
    transform: translateX(0); /* Sidebar is visible */
    z-index: 1000; /* Ensure it appears above other elements */
}

.dashboard-sidebar.closed {
    transform: translateX(-100%); /* Move sidebar out of view */
}
/*.dashboard-sidebar-section-header:hover {*/
/*    !*background-color: #0029a9; !* Vivid pinkish background *!*!*/
/*    !*color: #ffffff; !* White text for contrast *!*!*/
/*    !*transition: transform 0.3s ease, box-shadow 0.3s ease; !* Faster transition for responsive feel *!*!*/
/*    box-shadow: 0px 4px 15px rgba(4, 18, 77, 0.6); !* Glow effect around the item *!*/
/*    !*border-radius: 3px;*!*/
/*    !*padding: 2px;*!*/
/*    cursor: pointer; !* Change cursor to indicate interactivity *!*/
/*}*/

/* Add a sliding effect to the header */
.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    transition: transform 0.3s ease; /* Add transition for smooth slide */
}

.dashboard-header.hidden {
    transform: translateY(-4rem); /* Move header up (same distance as the height of the header) */
}

/* Adjust the main dashboard content */
.dashboard-main {
    flex: 1;
    padding: 20px;
    background-color: #ecf0f1;
    overflow-y: auto;
    transition: margin-left 0.3s ease, transform 0.3s ease; /* Transition for main content */
}

/* When the sidebar is open, adjust main content position */
.dashboard-main.sidebar-open {
    margin-left: 250px; /* Adjust margin when sidebar is open */
    transform: translateY(-4rem); /* Move main content up same distance as the header */
}

/* Adjust margin for main content when sidebar is open */
.dashboard-sidebar.open + .dashboard-main {
    margin-left: 250px; /* Adjust margin when sidebar is open */
}
/* Adjust margin for main content when sidebar is open */
.dashboard-sidebar.open + .dashboard-controls {
    margin-left: -50px; /* Adjust margin when sidebar is open */
}
.dashboard-main .dashboard-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10010;
}


.dashboard-sidebar nav ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
}

.dashboard-sidebar nav ul li {
    margin: 5px 0; /* Space between menu items */
    cursor: pointer; /* Pointer cursor for clickable items */
    padding: 10px; /* Add padding for better click area */
    border-radius: 5px; /* Rounded corners for list items */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
}

.dashboard-sidebar nav ul li:hover {
    background-color: #0029a9; /* Slightly lighter background on hover */
    color: #ffffff; /* Keep text white on hover */
}

.dashboard-sidebar-section-header {
    color: #ecf0f1; /* Use light grey for section headers */
    font-weight: bold; /* Bold section header text */
    margin-bottom: 15px; /* Space below the header */
    text-transform: uppercase; /* Uppercase for a modern touch */
    letter-spacing: 1px; /* Slightly increase letter spacing */
}
.dashboard-sidebar-big-nav-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid lightblue;
}
.dashboard-sidebar-big-nav-group-align-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-sidebar-align-pictogram-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.dashboard-sidebar-align-pictogram-text-column {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashboard-sidebar-align-pictogram-text-column svg {
    font-size: 2rem;
    margin-right: 10px;
}


.dashboard-sidebar-picto-text-fontsize {
    font-size: 1.2rem;
    color: white;
}

.dashboard-main {
    flex: 1;
    padding: 20px;
    background-color: #ecf0f1;
    overflow-y: auto;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-header h1 {
    margin: 0;
}

.dashboard-controls button {
    padding: 10px;
    background-color: #002083;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-controls button:hover {
    background-color: #002083;
}







/* Settings Section */
.dashboard-settings {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Buttons for actions */
.db-toggle-sidebar-button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 5px;
}



.db-toggle-sidebar-button.delete-button {
    background-color: #e74c3c;
    color: white;
}

.db-toggle-sidebar-button.delete-button:hover {
    background-color: #c0392b;
}

.db-toggle-sidebar-button {
    background-color: #4d555b
;
    color: white;
}

.db-toggle-sidebar-button:hover {
    background-color: #01163d;
}



.dashboard-sidebar-logo {
    width: 100%;
    height: 30%;

}
@media (max-width: 768px) {
    /* Default state for the overlay: fully transparent */
    .dashboard-main::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(253, 253, 253, 0.7); /* The final color you want when dimmed */
        opacity: 0; /* Initially transparent */
        z-index: 5; /* Place the overlay beneath dashboard-controls */
        pointer-events: none; /* Make sure the overlay itself doesn't block clicks */
        transition: opacity 0.3s ease; /* Smooth transition of opacity */
    }

    /* When the sidebar is open, fade in the dimming overlay */
    .dashboard-sidebar.open ~ .dashboard-main::before {
        opacity: 1; /* Fade in the dimming overlay */
    }

    /* Hide all child elements except dashboard-controls */
    .dashboard-sidebar.open ~ .dashboard-main > * {
        visibility: hidden;
    }

    /* Ensure .dashboard-controls remains visible */
    .dashboard-sidebar.open ~ .dashboard-main .dashboard-controls {
        visibility: visible; /* Make sure it's visible */
        position: relative; /* Retain its position */
        z-index: 10; /* Keep it above the dimming overlay */
        pointer-events: auto; /* Allow interaction with the controls */
    }

    /* Reset the visibility when the sidebar is not open */
    .dashboard-main > * {
        visibility: visible; /* Default state when the sidebar is closed */
    }




}
.plantaken-overzicht-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: .5rem;
}

/* Enable button - Green */
.enable-plantaak {
    background-color: #002083; /* Vibrant green */
    color: white;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 1.6rem;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.enable-plantaak:hover, .enable-plantaak:focus {
    background-color: #00124d; /* Darker green on hover */
}

/* Disable button - Blue-gray */
.disable-plantaak {
    background-color: #002083; /* Blue-gray */
    color: white;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 1.6rem;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.disable-plantaak:hover, .disable-plantaak:focus {
    background-color: #00124d; /* Darker blue-gray on hover */
}

/* Delete button - Red */
.delete-plantaak {
    background-color: #F44336; /* Strong red */
    color: white;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 1.3rem;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-plantaak:hover, .delete-plantaak:focus {
    background-color: #D32F2F; /* Darker red on hover */
}


.plannerrole-planning-item-header {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    padding-top: .5rem;
    padding-bottom: .5rem;
    justify-content: space-between;
    color: #1f1f1f; /* Very dark color for readability */
}

/* Base styling for the status text and light */
.status {
    display: flex;
    align-items: center;
    gap: 6px; /* Space between the light and text */
    font-weight: bold;
    font-size: 1.2rem;
}

/* Styling for the circular light indicator */
.status-light {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Glow effect */
    transition: background-color 0.3s ease;
}

/* Conditional styling for online and offline states */
.online .status-light {
    background-color: #4CAF50; /* Bright green */
    box-shadow: 0 0 8px #4CAF50; /* Green glow */
}

.offline .status-light {
    background-color: #F44336; /* Bright red */
    box-shadow: 0 0 8px #F44336; /* Red glow */
}

.align-plantaken-overzicht-status-end {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-bottom: 1px solid black;
    padding-bottom: .5rem;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background with transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it overlays other elements */
}

.modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow for modal depth */
    max-width: 450px;
    width: 100%;
    position: relative; /* For close button positioning */
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 2rem;
    color: #888;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-close:hover {
    color: #002083;
}

.voorrijkosten-title {
    text-align: center;
    color: #2c3e50;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.voorrijkosten-form {
    display: flex;
    flex-direction: column;
}

.voorrijkosten-label {
    font-size: 1.2rem;
    color: #34495e;
    margin-bottom: 8px;
}

.voorrijkosten-input {
    padding: 12px;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    font-size: 1.2rem;
    transition: border-color 0.3s;
    margin-bottom: 15px;
}

.voorrijkosten-input:focus {
    border-color: #002083;
    outline: none;
}

.voorrijkosten-button {
    padding: 12px;
    background-color: #002083;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s;
}

.voorrijkosten-button:hover {
    background-color: #2980b9;
}

/* Main container */
.dashboard-change-name {
    padding: 20px;
    background-color: #f9f9f9;  /* Light gray for background */
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
}

.dashboard-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;  /* Dark gray for high contrast */
}

/* Contact list */
.contact-list {
    list-style-type: none;
    padding: 0;
}

.contact-item {
    padding: 10px;
    background-color: #ffffff;  /* White background for items */
    border: 1px solid #ccc;  /* Light gray border for subtlety */
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;  /* Dark gray text for contrast */
    cursor: pointer;
    transition: background-color 0.3s ease;  /* Smooth transition for hover effect */
}

.contact-item:hover {
    background-color: #f0f0f0;  /* Light gray background on hover for subtle feedback */
}

/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);  /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #333;  /* Dark gray for high contrast */
}

.form-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;  /* Light gray border for consistency */
}

/* Functie titel options */
.functie-titel-options {
    display: flex;               /* Use flexbox to align items in a row */
    gap: 10px;                  /* Add space between the options */
    margin-left: 10px;          /* Align options with label */
    width: 100%;                /* Ensure the width takes up available space */
    align-items: center;        /* Center items vertically */
    flex-wrap: wrap;            /* Allow wrapping if necessary */
}

.functie-titel-option {
    padding: 10px 15px;         /* Increase padding for better touch area */
    background-color: #f6f6f6;  /* Light gray for visibility */
    color: #333;                /* Dark text for contrast */
    cursor: pointer;
    border-radius: 4px;
    flex: 1;                    /* Allow items to grow evenly */
    text-align: center;         /* Center text within options */
    transition: background-color 0.3s ease;  /* Smooth transition */
}

.functie-titel-option:hover {
    background-color: #C0C0C0;  /* Slightly darker gray on hover for visual feedback */
}

.functie-titel-option.selected {
    background-color: #6C757D;  /* Dark gray for selected state */
    color: white;               /* White text for contrast */
}

/* Buttons */
.assign-button {
    background-color: #24282c;  /* Subtle blue for primary actions */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;          /* Bold text for emphasis */
    transition: background-color 0.3s ease;  /* Smooth transition */
}

.assign-button:hover {
    background-color: #0056b3;  /* Darker blue on hover for feedback */
}

.close-button {
    background-color: #595656;  /* Soft red for close button */
    color: white;
    border: none;
    padding: 12px 18px;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;  /* Smooth transition */
}

.close-button:hover {
    background-color: #c82333;  /* Darker red on hover for feedback */
}


/* Reset some default styles to ensure consistency across browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

/* Body styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: white; /* Set the overall background color */
  color: black; /* Set the default text color */
}



/* Main content styles */
main {
  padding: 20px;
}



/* Responsive styles for smaller screens */
@media screen and (max-width: 600px) {
  main {
    padding: 10px;
  }
}

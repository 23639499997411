.m-header {
    background-color: #002083;
    color: #54B1FFFF;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1000;
    height: 4rem;
    transition: transform 0.3s ease; /* Smooth transition */

}
.m-header.hidden {
    transform: translateY(-100%); /* Slide header up off-screen */
}
.header-logo {
    height: 12rem;
    width: 12rem;
    padding-top: 1rem;
}

.m-header h1 {
    font-size: 21px;
    margin: 0;
    color: whitesmoke;
}

.title-and-menu {
    display: flex;
    align-items: center;
}

.title-and-menu-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: inherit; /* Ensure it inherits the color */
}

.nav-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3d413c;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    transform: translateX(-120%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 1;
    gap: 3rem;
}

.nav-links.open {
    transform: translate(0, 0);
}

.play-nav-open-menu {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.m-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.m-header li {
    margin: 10px 0;
}

.m-header a {
    position: relative; /* Make the link a positioned element */
    text-decoration: none;
    color: white;
    font-size: 17px;
    transition: color 0.3s ease;
}

.m-header .nav-link:hover,
.m-header .nav-link.active {
    color: greenyellow;
}

.m-header .nav-link::after,
.m-header .nav-link.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust to ensure the underline appears just below the text */
    width: 0;
    height: 2px; /* Adjust the height of the underline */
    background-color: greenyellow; /* Color of the underline */
    transition: width 0.3s ease-in-out;
}

.m-header .nav-link:hover::after,
.m-header .nav-link.active::after {
    width: 100%; /* Full width on hover */
}

.action-button {
    background-color: #FFFFFF; /* White background to make text readable */
    color: black; /* Google's blue for text color */
    border: none; /* Border around button */
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: start;
    text-align: center;
}

.action-button:hover {
    background-color: #E0E0E0; /* Light gray background on hover */
}

.action-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.5); /* Google's blue with opacity for focus */
}

.header-login-container-ul {
    list-style-type: none; /* Remove bullet points */
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center items vertically */
    padding: 0; /* Remove default padding */
    margin: 0 auto; /* Center on the page */
}

.header-login-container-li {
    margin: 0 10px; /* Add spacing between list items if needed */
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-login-button {
    padding: 8px 16px; /* Adjust padding for button styling */
    font-size: 16px;
    cursor: pointer;
    /* Additional styling as needed */
}

.menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
    margin-left: 50px;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: transform 0.3s ease, background-color 0.3s ease;


}

.menu-toggle.open .bar:first-child {
    transform: rotate(-45deg) translate(-11px, 13px);

}

.menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open .bar:last-child {
    transform: rotate(45deg) translate(-5px, -6px);


}



@media screen and (max-width: 769px) {
    .action-button {
        margin-left: 0;
    }
}

@media screen and (min-width: 769px) {
    .m-header {
        flex-direction: row;
        align-items: center;
    }

    .menu-toggle {
        display: none;
    }

    .nav-links {
        position: static;
        transform: none;
        flex-direction: row;
        background-color: transparent;
        width: auto;
        height: auto;
        overflow-y: visible;

    }

    .m-header ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


    /*.m-header li {*/
    /*    */
    /*}*/

    .m-header a {
        font-size: 16px;
    }

    .play-nav-open-menu {
        display: flex;
        flex-direction: row;
        align-items: center;

    }
}


/* DashboardWijtmanMachineAanmaken.css */

.DashboardWijtmanMachineAanmaken {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.machine-type-boxes {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.box {
    padding: 10px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
    text-align: center;
}



.machine-type-boxes .selected {
    border-color: #3d4144;
    background-color: #3d4144;
    color: white;
}

label {
    display: block;
    margin: 15px 0;
}

input {
    padding: 8px;
    margin-top: 5px;
    width: 100%;
    box-sizing: border-box;
}

.db-machine-aanmaken-verstuur-button {
    padding: 10px 20px;
    background-color: #3d4144;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.db-machine-aanmaken-verstuur-button:hover {
    background-color: #0056b3;
}

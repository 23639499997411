/* HeroSection.css */

.hero-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background-color: rgba(240, 243, 253, 0.58);
    padding: 50px;
    overflow: hidden; /* Ensures floating objects stay within bounds */
}


.hero-section-logo {
    width: 95%;
    height: auto;
}

.hero-section h1 {
    font-size: 3em; /* Adjust the font size as needed */
    margin-bottom: 0.3rem;
}

.hero-section p {
    font-size: 1.2em; /* Adjust the font size as needed */
    margin-bottom: 1.5rem;
}

.hero-section button {
    background: #002083; /* Set your preferred button background color */
    color: whitesmoke; /* Set your preferred button text color */
    padding: 10px 20px;
    border: none;
    border-radius: 5px; /* Optional: adds rounded corners to the button */
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1.5rem;
    margin-top: 20px; /* Add space between the button and text above */
}

.hero-section button:hover {
    background: #2980b9; /* Set your preferred button background color on hover */
    color: #fff; /* Set your preferred button text color on hover */
}

.rondleiding-button {
    display: flex;
    align-items: center;
    border-radius: 5px;
    gap: 10px;
}

.rondleiding-icon {
    font-size: 2rem;
    margin-left: 0.5rem;
}




/* Responsive styles */
@media screen and (max-width: 769px) {
    .hero-section {
        padding: 5px;
        padding-bottom: 1.5rem;
    }

    .hero-section h1 {
        font-size: 2.5em; /* Adjusted for smaller screens */
    }

    .hero-section p {
        font-size: 1.1em; /* Adjusted for smaller screens */
    }

    .hero-section button {
        font-size: 1.2rem;
    }



}


/* LoginSuccessful.css */

.login-successful-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: linear-gradient(135deg, #002083, #0033b3, #002083);
    animation: gradientShift 5s ease-in-out infinite, fadeInScaleRotate 0.6s ease-in-out;
    box-shadow: 0px 10px 30px rgba(0, 32, 131, 0.3);
    border-radius: 15px;
    padding: 30px;
    max-width: 90%;
    margin: auto;
    transform: rotateY(10deg);
    overflow: hidden;
}

.login-successful-logo {
    width: 80%; /* Large logo */
    max-width: 400px; /* Responsive limit */
    height: auto;
    margin-bottom: 20px;
    animation: bounceSpin 1.5s ease;
}

.login-successful-message {
    font-size: clamp(2rem, 3vw, 3rem);
    color: #e0e4ff; /* Light text color for contrast */
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: slideIn 1s ease-in-out;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.login-successful-subtext {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    color: #b3c1ff; /* Subtle, softer blue */
    margin-top: 10px;
    font-weight: 500;
    opacity: 0.9;
}

/* 3D Tilt + Fade-in and Scale Animation */
@keyframes fadeInScaleRotate {
    0% {
        opacity: 0;
        transform: scale(0.8) rotateY(10deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotateY(10deg);
    }
}

/* Animated gradient background shift */
@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.login-successful-container {
    background-size: 200% 200%;
}

/* Logo bounce and spin */
@keyframes bounceSpin {
    0% {
        transform: scale(0.5) rotate(0deg);
    }
    50% {
        transform: scale(1.1) rotate(180deg);
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
}

/* Slide-in text animation */
@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Glow effect on hover */
.login-successful-container:hover {
    box-shadow: 0px 15px 40px rgba(0, 32, 131, 0.5);
    transform: rotateY(10deg) scale(1.02);
}

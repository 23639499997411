/* DashboardWijtmanPersoneelAanmaken.css */

.DashboardWijtmanPersoneel {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.license-boxes, .role-boxes {
    display: flex;
    gap: 10px;
}

.box {
    padding: 10px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
    text-align: center;
}



.license-boxes .selected {
    border-color: #3d4144;
    background-color: #3d4144;
    color: white;
}
.role-boxes .selected {
    border-color: #3d4144;
    background-color: #3d4144;
    color: white;

}

.db-personeel-verstuur-button {
    padding: 10px 20px;
    background-color: #3d4144;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.db-personeel-verstuur-button:hover {
    background-color: #0056b3;
}


/* Section Styling */
.db-personeelsleden-section {
    padding: 20px;
    background-color: #f4f4f9;
    font-family: Arial, sans-serif;
}

.db-personeelsleden-section h3 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
}

/* Error Message */
.db-personeelsleden-section .error {
    font-size: 1rem;
    color: #d9534f;
    margin: 10px 0;
}

/* Table Styling */
.db-personeelsleden-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.db-personeelsleden-thead {
    background-color: #e6e6e6;
}

.db-personeelsleden-th, .db-personeelsleden-td {
    padding: .4rem;
    text-align: center;
    font-size: 1rem;
    border: 1px solid #ccc;
    color: #555;
}

.db-personeelsleden-tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* No Personnel Message */
.db-personeelsleden-table tr td[colspan="11"] {
    text-align: center;
    font-size: 1.1rem;
    color: #777;
    padding: 15px 0;
}

/* Action Buttons */
.db-personeelsleden-td button {
    padding: 6px 12px;
    font-size: 0.9rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 4px;
    transition: background-color 0.3s ease;
}

.db-personeelsleden-td button:hover {
    opacity: 0.9;
}

.db-personeelsleden-td button:focus {
    outline: none;
}

/* Enable Button */
.personeelsleden-toggle-button.enable {
    background-color: #4caf50; /* Green for Enable */
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
}

/* Disable Button */
.personeelsleden-toggle-button.disable {
    background-color: #ff9800; /* Orange for Disable */
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
}

/* Delete Button */
.personeelsleden-delete-button {
    background-color: #f44336; /* Red for Delete */
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 5px; /* Space between buttons */
}

